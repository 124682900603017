import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable()
export class ApiService {
  public constructor(public $http: HttpClient) {
  }

  public get(url: string, options: Object = null, persist: boolean = true): Observable<Object> {

    const subject  = new BehaviorSubject<Object>(null);
    const cacheKey = this.getCacheKey(url, options);
    const cache    = this.getCache(cacheKey);
    if (!!cache) {
      subject.next(cache);
    }
    const request: Observable<Object> = options === null ? this.$http.get(url) : this.$http.get(url, options);
    request.subscribe((response: Object) => {
      if (persist) {
        this.setCache(cacheKey, response);
      }
      if(JSON.stringify(subject.getValue()) != JSON.stringify(response)) {
        subject.next(response);
      }
    });

    return subject.pipe(filter(value => value != null));
  }

  private setCache(url: string, response: any): void {
    localStorage.setItem(url, JSON.stringify(response));
  }

  private getCache(url: string): Object {
    return JSON.parse(localStorage.getItem(url));
  }

  private getCacheKey(url: string, options: Object): string {
    if (options === null) {
      return url;
    }
    return url + JSON.stringify(options);
  }
}
