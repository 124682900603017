import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlayerComponent} from './player.component';
import {GraphicModule} from '../graphic/graphic.module';
import {PlayerService} from './player.service';
import { MinutesPipe } from './minutes.pipe';

@NgModule({
  declarations: [PlayerComponent, MinutesPipe],
  exports: [PlayerComponent],
  imports: [
    CommonModule,
    GraphicModule
  ],
  providers: [
    PlayerService
  ]
})
export class PlayerModule {
}
