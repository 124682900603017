import {Component, OnInit} from '@angular/core';
import {ApiService} from '../_service/api.service';

interface Gallery {
  images: Image[];
}

interface Image {
  id: number;
  name: string;
  type: string;
  file: string;
  media_type: string;
}

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  public slides: string[] = [];

  public current: number = 0;

  constructor(public apiService: ApiService) {
    this.apiService.get('/api/images/category/slider').subscribe((response: Gallery) => {
      this.slides = response.images.map((image: Image) => {
        return '/media/name/' + image.name;
      })
    });
  }

  ngOnInit(): void {
    setInterval(() => {
      this.next();
    }, 10000);
  }

  public next(): void {
    let newIndex: number = this.current + 1;
    if (newIndex >= this.slides.length) {
      newIndex = 0;
    }
    this.current = newIndex;
  }

}
