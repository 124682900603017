import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrollService} from './scroll.service';
import {SettingsService} from './settings.service';
import {ApiService} from './api.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    ScrollService,
    SettingsService,
    ApiService
  ]
})
export class ServiceModule {
}
