import {Component, Input, OnInit} from '@angular/core';
import {GalleryService} from './gallery.service';
import {Item, LightboxService} from '../lightbox/lightbox.service';

interface Gallery {
  images: Image[];
}

interface Image {
  id: number;
  name: string;
  type: string;
  file: string;
  media_type: string;
}

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  @Input() api: string;

  public items: Item[] = [];

  constructor(public galleryService: GalleryService, public lightboxService: LightboxService) {
  }

  ngOnInit(): void {
    this.galleryService.fetchImages(this.api).subscribe((images: Gallery) => {
      this.items = images.images.map((image: Image) => {
        return {
          thumbnail: '/thumbnail/name/' + image.name,
          image: '/media/name/' + image.name
        }
      });
    });
  }

  public openLightbox(imageNum: number): void {
    this.lightboxService.openLightbox(this.items, imageNum);
  }
}
