import { Component, OnInit } from '@angular/core';
import {SettingsService} from '../_service/settings.service';

@Component({
  selector: 'social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent {

  public instagramUrl: string;
  public facebookUrl: string;
  public youtubeUrl: string;

  constructor(public settingsService: SettingsService) {
    this.settingsService.get('social/instagram').subscribe((value: string) => {
      this.instagramUrl = value;
    });
    this.settingsService.get('social/facebook').subscribe((value: string) => {
      this.facebookUrl = value;
    });
    this.settingsService.get('social/youtube').subscribe((value: string) => {
      this.youtubeUrl = value;
    });
  }

}
