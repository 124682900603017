import { Injectable } from '@angular/core';
import {ScrollService} from '../_service/scroll.service';

interface Anchor {
  name: string;
  icon: string;
}

@Injectable({
  providedIn: 'root'
})
export class GuideService {

  public anchors: Anchor[] = [];
  public current: string = null;

  constructor(public scrollService: ScrollService) {
    this.scrollService.get().subscribe(() => {
      this.findCurrent();
    });
  }

  public register(name: string, icon: string): void {
    this.anchors.push({name: name, icon: icon});
    this.findCurrent();
  }

  public unregister(name: string): void {
    this.anchors.forEach((anchor: Anchor, index: number) => {
      if(anchor.name === name) {
        this.anchors.splice(index, 1);
      }
    });
  }

  public clear(): void {
    this.anchors = [];
  }

  public findCurrent(): void {
    this.anchors.forEach((anchor: Anchor) => {
      const element: HTMLElement = document.getElementById(anchor.name);
      if(!element) {
        return;
      }
      const middle: number = window.innerHeight / 2;
      const rect: DOMRect = document.getElementById(anchor.name).getBoundingClientRect();
      if(rect.top < middle && rect.bottom > middle) {
        this.current = anchor.name;
      }
    });
  }
}
