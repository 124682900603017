import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GuideService} from '../guide/guide.service';

@Component({
  selector: 'wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit, OnDestroy {

  @Input()
  public id: string = null;

  @Input()
  public icon: string = 'warning';

  constructor(public guideService: GuideService) {
  }

  ngOnInit(): void {
    if(this.getAnchorName()) {
      this.guideService.register(this.getAnchorName(), this.icon);
    }
  }

  ngOnDestroy(): void {
    if(this.getAnchorName()) {
      this.guideService.unregister(this.getAnchorName());
    }
  }

  public getAnchorName(): string {
    return this.id;
  }
}
