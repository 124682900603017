<wrapper dynamic>
  <div class="outer">
    <graphic src="/static/assets/icon.png"></graphic>
    <div class="inner">
      <div class="social-media">
        <social-media></social-media>
      </div>
      <div class="links">
        <a href="/imprint">Impressum</a>
      </div>
    </div>
  </div>
</wrapper>
