import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GalleryComponent} from './gallery.component';
import {GalleryService} from './gallery.service';
import {PhotoModule} from '../photo/photo.module';

@NgModule({
  declarations: [GalleryComponent],
  exports: [
    GalleryComponent
  ],
  imports: [
    CommonModule,
    PhotoModule
  ],
  providers: [
    GalleryService
  ]
})
export class GalleryModule {
}
