<wrapper fullscreen id="home" icon="home">
  <slider></slider>
</wrapper>

<wrapper id="pictures" icon="camera">
  <book>
    <page>
      <h2>Portraits</h2>
      <gallery api="api/images/category/portraits"></gallery>
    </page>
    <page>
      <h2>Landschaften</h2>
      <gallery api="api/images/category/landschaften"></gallery>
    </page>
  </book>
</wrapper>

<wrapper id="songs" icon="audiotrack">
  <h2>Songs</h2>
  <song *ngFor="let song of songs; let i = index" [index]="i" [song]="song"></song>
</wrapper>
