import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

export interface Track {
  title: string;
  cover: string;
  source: string;
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  public playing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public playlist: BehaviorSubject<Track[]> = new BehaviorSubject<Track[]>([]);
  public currentTrack: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public time: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public maxTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public seekTrigger: Subject<void> = new Subject<void>();

  public constructor() {
    this.assignEvents();
  }

  public play(): void {
    this.playing.next(true);
  }

  public pause(): void {
    this.playing.next(false);
  }

  public toggle(): void {
    this.playing.next(!this.playing.getValue());
  }

  public setTrack(index: number): void {
    this.currentTrack.next(index);
    this.refreshNotification();
  }

  public setPlaylist(playlist: Track[]): void {
    this.playlist.next(playlist);
    this.currentTrack.next(null);
  }

  public seek(seconds: number): void {
    this.updateTime(seconds);
    this.seekTrigger.next();
    this.play();
  }

  public updateTime(seconds: number): void {
    this.time.next(seconds);
  }

  public setMaxTime(seconds: number): void {
    this.maxTime.next(seconds);
  }

  public previous(): void {
    let newIndex: number = this.currentTrack.getValue() - 1;
    if(newIndex < 0) {
      newIndex = this.playlist.getValue().length - 1;
    }
    this.pause();
    this.setTrack(newIndex);
    this.play();
  }

  public next(): void {
    let newIndex: number = this.currentTrack.getValue() + 1;
    if(newIndex >= this.playlist.getValue().length) {
      newIndex = 0;
    }
    this.pause();
    this.setTrack(newIndex);
    this.play();
  }

  public getCurrentTrack(): Track {
    return this.playlist.getValue()[this.currentTrack.getValue()];
  }

  public refreshNotification(): void {
    if('mediaSession' in navigator && this.getCurrentTrack()) {
      // @ts-ignore
      navigator.mediaSession.metadata = new MediaMetadata(
        {
          title: this.getCurrentTrack().title,
          artist: 'Skada',
          album: 'Skada',
          artwork: [
            {src: this.getCurrentTrack().cover, sizes: '512x512', type: 'image/png'}
          ]
        }
      );
    }
  }

  public assignEvents(): void {
    if ('mediaSession' in navigator) {
      // @ts-ignore
      navigator.mediaSession.setActionHandler('play', this.play.bind(this));
      // @ts-ignore
      navigator.mediaSession.setActionHandler('pause', this.pause.bind(this));
      // @ts-ignore
      navigator.mediaSession.setActionHandler('previoustrack', this.previous.bind(this));
      // @ts-ignore
      navigator.mediaSession.setActionHandler('nexttrack', this.next.bind(this));
    }
  }
}
