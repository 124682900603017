import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {PlayerService, Track} from './player.service';
import {ComponentFixture} from '@angular/core/testing';
import {delay, filter} from 'rxjs/operators';

@Component({
  selector: 'player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements AfterViewInit {

  @ViewChild('audio') audio: ComponentFixture<HTMLAudioElement>;

  public cover: string = '/static/assets/icon.png';
  public title: string = '';
  public source: string = null;

  constructor(public playerService: PlayerService) {
    this.playerService.currentTrack.pipe().subscribe((index: number) => {
      if(index === null) {
        return this.source = null;
      }
      const track: Track = this.playerService.playlist.getValue()[index];
      this.cover = track.cover;
      this.title = track.title;
      this.source = track.source;
    });
  }

  ngAfterViewInit(): void {
    this.playerService.playing.pipe(filter(value => value === true), delay(0)).subscribe(() => {
      this.audio.nativeElement.play();
    });
    this.playerService.playing.pipe(filter(value => value !== true)).subscribe(() => {
      this.audio.nativeElement.pause();
    });
  }

  public toggle(): void {
    this.playerService.toggle();
  }

  public close(): void {
    this.playerService.setTrack(null);
  }

  public timeUpdate(): void {
    this.playerService.updateTime(this.audio.nativeElement.currentTime);
  }

  public loadMetaData(): void {
    this.playerService.setMaxTime(this.audio.nativeElement.duration);
  }

  public ended(): void {
    this.playerService.next();
  }
}
