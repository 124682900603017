import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import {WrapperModule} from '../wrapper/wrapper.module';
import {GraphicModule} from '../graphic/graphic.module';
import {SocialMediaModule} from '../social-media/social-media.module';



@NgModule({
    declarations: [FooterComponent],
    exports: [
        FooterComponent
    ],
  imports: [
    CommonModule,
    WrapperModule,
    GraphicModule,
    SocialMediaModule
  ]
})
export class FooterModule { }
