import {Component} from '@angular/core';
import {LightboxService} from './lightbox.service';

@Component({
  selector: 'lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent {

  constructor(public lightboxService: LightboxService) {
  }

  public close(): void {
    this.lightboxService.closeLightbox();
  }

  public next(): void {
    this.lightboxService.nextImage();
  }

  public previous(): void {
    this.lightboxService.previousImage();
  }

  public setImage(index: number): void {
    this.lightboxService.switchImage(index);
  }
}
