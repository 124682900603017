import { Component, OnInit } from '@angular/core';
import {GuideService} from './guide.service';

@Component({
  selector: 'guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  constructor(public guideService: GuideService) { }

  ngOnInit(): void {
  }

  public getHref(name: string): string {
    return '#' + name;
  }
}
