import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoComponent } from './photo.component';
import {GraphicModule} from '../graphic/graphic.module';



@NgModule({
  declarations: [PhotoComponent],
  exports: [
    PhotoComponent
  ],
  imports: [
    CommonModule,
    GraphicModule
  ]
})
export class PhotoModule { }
