<div class="player" [class.playing]="!!source">
  <div class="progress">
    <div class="history" [style.width]="(playerService.time | async) / (playerService.maxTime | async) * 100 + '%'"></div>
  </div>
  <div class="controls">
    <div class="cover" accesskey="p" (click)="toggle()">
      <graphic [src]="cover"></graphic>
      <i class="material-icons" *ngIf="!(playerService.playing | async)">play_arrow</i>
      <i class="material-icons" *ngIf="(playerService.playing | async)">pause</i>
    </div>
    <div class="info" (click)="toggle()">
      <div class="title">{{title}}</div>
      <div class="time">
        <span class="current">{{playerService.time | async | minutes}}</span> / <span class="max">{{playerService.maxTime | async | minutes}}</span>
      </div>
    </div>
    <div class="close" (click)="close()">
      <i class="material-icons">close</i>
    </div>
  </div>
</div>
<audio #audio preload="metadata" [src]="source" (timeupdate)="timeUpdate()" (loadedmetadata)="loadMetaData()" (ended)="ended()"></audio>
