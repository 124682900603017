import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import {SliderModule} from '../slider/slider.module';
import {BookModule} from '../book/book.module';
import {PageModule} from '../book/page/page.module';
import {GalleryModule} from '../gallery/gallery.module';
import {SongModule} from '../song/song.module';
import {WrapperModule} from '../wrapper/wrapper.module';



@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    SliderModule,
    BookModule,
    PageModule,
    GalleryModule,
    SongModule,
    WrapperModule
  ]
})
export class HomeModule { }
