import {Component, Input, OnInit} from '@angular/core';
import {PlayerService, Track} from '../player/player.service';

@Component({
  selector: 'song',
  templateUrl: './song.component.html',
  styleUrls: ['./song.component.scss']
})
export class SongComponent implements OnInit {

  @Input() index: number;
  @Input() song: Track;

  constructor(public playerService: PlayerService) { }

  ngOnInit(): void {
  }

  public toggle(): void {
    if(!this.isCurrentTrack()) {
      this.playerService.setTrack(this.index);
      this.playerService.play();
    }
    else {
      this.playerService.toggle();
    }
  }

  public isPlaying(): boolean {
    return this.playerService.playing.getValue() === true;
  }

  public isCurrentTrack(): boolean {
    return this.playerService.currentTrack.getValue() === this.index;
  }
}
