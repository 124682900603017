import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LightboxComponent} from './lightbox.component';
import {SelectorModule} from './selector/selector.module';
import {HammerModule} from '@angular/platform-browser';
import {GraphicModule} from '../graphic/graphic.module';
import {LightboxService} from './lightbox.service';

@NgModule({
  declarations: [LightboxComponent],
  imports: [
    CommonModule,
    SelectorModule,
    GraphicModule,
    HammerModule
  ],
  exports: [
    LightboxComponent
  ],
  providers: [
    LightboxService
  ]
})
export class LightboxModule {
}
