import {Injectable} from '@angular/core';

export interface Item {
  image: string;
  thumbnail: string;
}

@Injectable({
  providedIn: 'root'
})
export class LightboxService {

  public items: Item[] = [];
  public index: number = 0;
  public open: boolean = false;

  constructor() {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      if (this.open) {
        if (e.key.toLowerCase() == 'escape') {
          this.closeLightbox();
        } else if (e.key.toLowerCase() == 'arrowleft') {
          this.previousImage();
        } else if (e.key.toLowerCase() == 'arrowright') {
          this.nextImage();
        } else {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  public openLightbox(items: Item[], index: number = 0): void {
    this.items = items;
    this.switchImage(index);
    this.open = true;

    document.body.style.overflowY = 'hidden';
  }

  public switchImage(index: number): void {
    this.index = index;
  }

  public previousImage(): void {
    this.index = this.index - 1;
    if (this.index < 0) {
      this.index = this.items.length - 1;
    }
  }

  public nextImage(): void {
    this.index = this.index + 1;
    if (this.index >= this.items.length) {
      this.index = 0;
    }
  }

  public closeLightbox(): void {
    this.open = false;

    document.body.style.overflowY = 'auto';
  }
}
