import {BrowserModule, Meta, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {SettingsService} from './_service/settings.service';
import {ServiceModule} from './_service/service.module';
import {PlayerModule} from './player/player.module';
import {AppRoutingModule} from './app-routing.module';
import {LightboxModule} from './lightbox/lightbox.module';
import {WrapperModule} from './wrapper/wrapper.module';
import {FooterModule} from './footer/footer.module';
import {GuideModule} from './guide/guide.module';

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        BrowserModule,
        ServiceModule,
        PlayerModule,
        AppRoutingModule,
        LightboxModule,
        WrapperModule,
        FooterModule,
        GuideModule
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  public constructor(
    private settingsService: SettingsService,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.settingsService.get('page/title').subscribe((title: string) => {
      this.titleService.setTitle(title);
    });
    this.settingsService.get('seo/keywords').subscribe((keywords: string) => {
      this.metaService.updateTag({name: 'keywords', content: keywords});
    });
    this.settingsService.get('seo/description').subscribe((description: string) => {
      this.metaService.updateTag({name: 'description', content: description});
    });
  }
}
