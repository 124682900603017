import { Component, OnInit } from '@angular/core';
import {PlayerService, Track} from '../player/player.service';
import {ApiService} from '../_service/api.service';

interface TrackResponse {
  tracks: {
    id: number;
    name: string;
    type: string;
    file: string;
    media_type: string;
  }[];
}

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public songs: Track[];

  constructor(public apiService: ApiService, public playerService: PlayerService) {
    this.apiService.get('/api/tracks').subscribe((response: TrackResponse) => {
      this.songs = response.tracks.map((track) => {
        return {
          title: track.name,
          cover: '/static/assets/icon.png',
          source: '/media/name/' + track.name
        };
      });
      this.playerService.setPlaylist(this.songs);
    })
  }

  ngOnInit(): void {
  }

}
