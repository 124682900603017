<span>Get in touch with Skada</span>
<a [href]="instagramUrl" *ngIf="instagramUrl">
  <i class="fab fa-instagram"></i>
</a>
<a [href]="youtubeUrl" *ngIf="youtubeUrl">
  <i class="fab fa-youtube"></i>
</a>
<a [href]="facebookUrl" *ngIf="facebookUrl">
  <i class="fab fa-facebook"></i>
</a>
