import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectorComponent} from './selector.component';
import {GraphicModule} from '../../graphic/graphic.module';

@NgModule({
  declarations: [SelectorComponent],
  imports: [
    CommonModule,
    GraphicModule
  ],
  exports: [
    SelectorComponent
  ]
})
export class SelectorModule {
}
