<router-outlet></router-outlet>

<skada-footer>
</skada-footer>

<guide></guide>

<lightbox></lightbox>
<player></player>


