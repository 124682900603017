import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../_service/api.service';


@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(public apiService: ApiService) {
  }

  public fetchImages(api: string): Observable<any> {
    return this.apiService.get(api);
  }
}
