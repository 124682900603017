import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ScrollService} from '../_service/scroll.service';

@Component({
  selector: 'graphic',
  templateUrl: './graphic.component.html',
  styleUrls: ['./graphic.component.scss']
})
export class GraphicComponent implements OnInit, OnChanges {

  @Input() src: string;
  @Input() lazy: boolean = true;

  @Output() load: EventEmitter<void> = new EventEmitter<void>();

  public loaded: boolean = false;

  private isInitialized = false;

  constructor(public scrollService: ScrollService, public elRef: ElementRef) { }

  ngOnInit(): void {
    if(this.lazy) {
      this.scrollService.get().subscribe(() => {
        this.checkScrollPos();
      });
      this.checkScrollPos();
    }
    else {
      this.isInitialized = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loaded = false;
  }

  public onLoad(): void {
    this.loaded = true;
    this.load.emit();
  }

  public isInView(): boolean {
    return this.isInitialized;
  }

  private checkScrollPos(): void {
    if (!this.isInitialized && this.scrollService.isInView(this.elRef.nativeElement)) {
      this.isInitialized = true;
    }
  }
}
