<div class="slides">
  <graphic *ngFor="let slide of slides; let i = index" [src]="slide" [class.current]="i === current"></graphic>
</div>

<div class="content">
  <graphic src="/static/assets/icon.png"></graphic>
  <div>
    <h1>Skada</h1>
    <h3>Fotografie und Musik</h3>
  </div>
</div>

<div class="controls">

</div>
