import {Component, Input} from '@angular/core';

@Component({
  selector: 'photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent {

  @Input() src: string;

  public rotation: number;

  constructor() {
    this.rotation = Math.round(Math.random() * 12 - 6);
  }

  public getTransform(): string {
    return 'rotate(' + this.rotation + 'deg)'
  }
}
